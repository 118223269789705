import { Link } from "react-router-dom";
import "./css/index.css";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
  const Bottom = () => {
  const targetRef = useIntersectionObserver(0.2);

    return (
      <section className="bottom-section hidden-section" ref={targetRef}>
        <div className="bottom-container">
          <div className="description-container">
            <div className="service-center-container">
              <div className="left-container">
                <div className="inquiry-wrapper">
                  <p className="text">
                    SKPOS에
                    <br />
                    궁금한 사항이 있으신가요?
                  </p>
                  <Link to="/user/inquiry" className="inquiry-btn">
                    고객 문의 바로가기
                  </Link>
                </div>
              </div>
              <div className="line" />
              <div className="right-container">
                {guideArr.map((item, idx) => (
                  <div className="guide-container" key={idx}>
                    <div className="box">{item.title}</div>
                    <p className="description">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

export default Bottom;

const guideArr = [
  {
    title: "운영시간",
    description: (
      <>
        {`평일 `}
        09:30 ~ 18:00 <br/>
        {`점심시간 `}
        12:00 ~ 13:00
      </>
    ),
  },
  {
    title: "문의전화",
    description: <p>1533 - 4787</p>,
  },
  // {
  //   title: "카드 단말기 문의",
  //   description: <p>1577 - 5420</p>,
  // },
];
