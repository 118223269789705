import { Link } from "react-router-dom";
import HoverInquiryContainer from "../HoverInquiry/container/HoverInquiryContainer";

type Props = {
  headerList: {
    title: string;
    link: string;
  }[];
  isLogin: string | undefined;
  isHover: boolean;
  LoginBtnClicked: () => void;
  MouseHover: () => void;
  LogOutBtn: () => void;
  MouseHoverOut: () => void;
};

const PcHeader = ({
  isHover,
  headerList,
  isLogin,
  MouseHover,
  LogOutBtn,
  LoginBtnClicked,
  MouseHoverOut,
}: Props) => {
  return (
    <div className="header-container">
      <div className="logo-img-container">
        <Link to="/">
          <img
            src="/assets/skpos-logo.png"
            srcSet="/assets/skpos-logo@2x.png 2x, /assets/skpos-logo@3x.png 3x"
            alt="skpos"
          />
        </Link>
      </div>
      <nav className="header-nav">
        <ul className="header-ul">
          {headerList.map(({ link, title }, idx) => (
            <li
              className="header-menu"
              key={idx}
              onMouseOver={idx === 4 ? MouseHover : undefined}
              onMouseOut={MouseHoverOut}
            >
              {idx === 4 ? (
                <>
                  <div className="header-menu-text">
                    {title}
                    {isHover && <HoverInquiryContainer />}
                  </div>
                </>
              ) : (
                <Link to={link} className="header-menu-text">
                  {title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="auth-container">
        <div className={`auth-box ${isLogin && "logout-box"}`}>
          {isLogin ? (
            <button onClick={LogOutBtn}>로그아웃</button>
          ) : (
            <button onClick={LoginBtnClicked}>로그인</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PcHeader;
