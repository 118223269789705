import  { useState, useCallback } from "react";
import { UserAgentType } from "../../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../../hooks/UseIntersectionObserver";
import MainSection5 from "../MainSection5";
type Props = {
  userAgent: UserAgentType;
};
const MainSection5Container = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.5);
  const [crtIdx, setCrtIdx] = useState<number>(0);
  const SelectedBtnClicked = useCallback(
    (idx: number) => {
      setCrtIdx(idx);
    },
    [crtIdx]
  );

  return (
    <MainSection5
      ref={targetRef}
      crtIdx={crtIdx}
      SelectedBtnClicked={SelectedBtnClicked}
      userAgent={userAgent}
    />
  );
};

export default MainSection5Container;
