import "./css/index.css";

const MouseScroll = () => {
  return (
    <div className="mouse-scroll">
      <div className="mouse-scroll-img-box">
        <img
          src="/assets/mouse-scroll.svg"
          alt="mouse-scroll"
          className="mouse-scroll-img"
        />
      </div>
      <p className="scroll-text montserrat">SCROLL</p>
    </div>
  );
}

export default MouseScroll