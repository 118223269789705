import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import { UserAgentType } from "../../../interface/common/common.inferface";
import "./css/index.css";
type Props={
  userAgent:UserAgentType;
}
const AlertTalkSection7 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.2);
  const targetRef2 = useIntersectionObserver(0.2);

  return (
    <section className="alert-talk-section7">
      <div className="text-wrapper hidden-section" ref={targetRef}>
        <p className="main-text">매장관리의 모든것</p>
        <p className="sub-text">
          {userAgent !== "mb"
            ? `포스부터 마케팅까지! 매장관리의 모든게 10원 이면 됩니다.`
            : `포스부터 마케팅까지!
매장관리의 모든게 10원 이면 됩니다.`}
        </p>
      </div>
      <img
        ref={targetRef2}
        src="/assets/alerttalk/section07/coin.gif"
        alt="coin"
        className="coin hidden-section"
      />
    </section>
  );
};

export default AlertTalkSection7