import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import FooterContainer from "../../components/Footer/container/FooterContainer";
import AppPromotion from "../../components/AppPromotion/AppPromotion";
import AlertTalkSection3Container from "../../components/AlertTalkSection/AlertTalkSection3/container/AlertTalkSection3Container";
import AlertTalkSection5 from "../../components/AlertTalkSection/AlertTalkSection5/AlertTalkSection5";
import AlertTalksection6 from "../../components/AlertTalkSection/AlertTalkSection6/AlertTalksection6";
import AlertTalkSection7 from "../../components/AlertTalkSection/AlertTalkSection7/AlertTalkSection7";
import AlertTalkSection8 from "../../components/AlertTalkSection/AlertTalkSection8/AlertTalkSection8";
import AlertTalkSection9 from "../../components/AlertTalkSection/AlertTalkSection9/AlertTalkSection9";
import MarketingPromotionLists from "../../components/MarketingPromotionLists/MarketingPromotionLists";
import AlertTalkSection4 from "../../components/AlertTalkSection/AlertTalkSection4/AlertTalkSection4";
import Bottom from "../../components/Bottom/Bottom";
import MouseScroll from "../../components/MouseScroll/MouseScroll";

type Props = {
  userAgent: UserAgentType;
};
const AlertTalk = ({ userAgent }: Props) => {
  return (
    <main className="alert-talk-page">
      <section className="alert-talk-banner">
        <video
          loop
          autoPlay
          muted
          playsInline
          src="/assets/alerttalk/alert-main-video.mp4"
        />
        <MouseScroll />
      </section>
      <AppPromotion userAgent={userAgent} imgSrc={"alerttalk"} />
      <AlertTalkSection3Container userAgent={userAgent} />
      <AlertTalkSection4 userAgent={userAgent} />
      <AlertTalkSection5 userAgent={userAgent} />
      <AlertTalksection6 userAgent={userAgent} />
      <AlertTalkSection7 userAgent={userAgent} />
      <AlertTalkSection8 userAgent={userAgent} />
      <AlertTalkSection9 userAgent={userAgent} />
      <MarketingPromotionLists
        userAgent={userAgent}
        title={`마케팅 하면 매출이 올라요`}
      />
      <Bottom />
      <FooterContainer />
    </main>
  );
};

export default AlertTalk;
