import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import InquiryButtonContainer from "../../InquiryButton/container/InquiryButtonContainer";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import MainSection10Card from "../../MainSection10Card/MainSection10Card";
type Props = {
  userAgent: UserAgentType;
};
const MainSection10 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.1);
  const contentArr = [
    {
      img: "img1",
      title: `바코드가 없는
상품은 품명으로`,
    },

    {
      img: "img2",
      title: "전표 단위",
      sub: `반품, 불러오기, 재발행 등`,
    },
    {
      img: "img3",
      title: "중요한 매입 결제 관리",
      sub:
        userAgent === "pc"
          ? `엑셀 매입, 결제 현황, 
거래 원장으로 체계적인 결제 관리`
          : `엑셀 매입, 결제 현황, 
거래 원장으로 체계적인
결제 관리`,
      subimg: true,
    },
  ];
  return (
    <section className="main-section10 ">
      <div className="wrapper">
        <div className="text-wrapper hidden-section" ref={targetRef}>
          <p className="text">
            도소매 매장에{userAgent === "mb" && <br />}{" "}
            <span className="color-text">꼭 필요한 기능</span>
          </p>
        </div>
        <div className="content-wrapper">
          {contentArr.map(({ img, title, sub, subimg }, idx) => (
            <MainSection10Card
              key={idx}
              userAgent={userAgent}
              img={img}
              title={title}
              sub={sub}
              subimg={subimg}
            />
          ))}
        </div>
        <div className="btn-box">
          <InquiryButtonContainer />
        </div>
      </div>
    </section>
  );
};

export default MainSection10;
