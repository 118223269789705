import AlertTalk from '../AlertTalk'
import { UserAgentType } from '../../../interface/common/common.inferface'
type Props={
  userAgent:UserAgentType;
}
const AlertTalkContainer = ({userAgent}:Props) => {
  return (
    <AlertTalk userAgent={userAgent}/>
)
}

export default AlertTalkContainer