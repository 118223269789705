import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import MarketingPromotionLists from "../../MarketingPromotionLists/MarketingPromotionLists";
import AppPromotion from "../../AppPromotion/AppPromotion";

type Props = {
  userAgent: UserAgentType;
};
const MainSection12 = ({ userAgent }: Props) => {
  return (
    <section className="main-section12">
      <div className="main-section12-wrapper">
        <AppPromotion userAgent={userAgent} imgSrc={"main"} />
        <MarketingPromotionLists
          title="알림톡 발송 비용 10원!"
          userAgent={userAgent}
          subTitle={
            userAgent !== "mb"
              ? `우리동네 고객에게 직접 마케팅 하세요`
              : `우리동네 고객에게 직접
마케팅 하세요`
          }
        />
      </div>
    </section>
  );
};

export default MainSection12;
