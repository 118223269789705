import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
import StoreCard from "../StoreCard/StoreCard";
import InquiryButtonContainer from "../InquiryButton/container/InquiryButtonContainer";
import { useLocation } from "react-router-dom";
type Props = {
  title: string;
  subTitle?:string;
  userAgent: UserAgentType;
};
const MarketingPromotionLists = ({ userAgent, title, subTitle }: Props) => {
  const targetRef4 = useIntersectionObserver(0.1);
  const targetRef5 = useIntersectionObserver(0.1);
  const targetRef6 = useIntersectionObserver(0);
  const storeArr = [
    {
      content: "매장 홍보가 필요하다",
    },
    {
      content: "디자인 및 광고 관리할 인력이 없다",
    },
    {
      content:
        userAgent !== "mb"
          ? "필터링된 고객에게 타겟 발송을 하고 싶다"
          : `필터링된 고객에게
타겟 발송을 하고 싶다`,
    },
    {
      content:
        userAgent !== "mb"
          ? "쿠폰으로 고객들의 재방문을 유도하고 싶다"
          : `쿠폰으로 고객들의 재방문을 
유도하고 싶다`,
    },
    {
      content: "앱으로 주문과 결제까지 받고 싶다",
    },
    {
      content:
        userAgent !== "mb"
          ? "매출로 이어지는 직접적인 마케팅이 필요하다"
          : `매출로 이어지는 직접적인 
마케팅이 필요하다`,
    },
  ];
  return (
    <section className="marketing-promotion-lists-section">
      <div className="marketing-promotion-lists">
        <div className="text-wrapper hidden-section" ref={targetRef4}>
          <p className="text">{title}</p>
          <p className="sub-text">
            {subTitle}
          </p>
        </div>
        <div className="img-box hidden-section" ref={targetRef5}>
          <img
            src={`/assets/marketingPromotion/${userAgent}/marketing-promotion.png`}
            srcSet={`
                      /assets/marketingPromotion/${userAgent}/marketing-promotion@2x.png 2x,
                      /assets/marketingPromotion/${userAgent}/marketing-promotion@3x.png 3x,
                    `}
            alt="img-4"
            className="img"
          />
        </div>
        <div className="card-wrapper hidden-section" ref={targetRef6}>
          <p className="card-title">이런 매장에 이용하시면 좋아요</p>
          <div className="card-lists">
            {storeArr.map(({ content }, idx) => (
              <StoreCard key={idx} content={content} />
            ))}
          </div>
          <div className="button-box">
            <InquiryButtonContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketingPromotionLists;
