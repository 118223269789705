import "./css/index.css";
import { UserAgentType } from '../../../interface/common/common.inferface'
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";

type Props={
    userAgent:UserAgentType;
}
const AlertTalkSection9 = ({userAgent}:Props) => {
  const targetRef = useIntersectionObserver(0.2);
  const targetRef2 = useIntersectionObserver(0.3);

  return (
    <section className="alert-talk-section9">
      <p className="title hidden-section" ref={targetRef}>
        {userAgent !== "mb"
          ? `쿠폰으로 단골 고객을 만들어 보세요`
          : `쿠폰으로
단골 고객을 만들어 보세요`}
      </p>
      <img
        ref={targetRef2}
        className="img hidden-section"
        src={`/assets/alerttalk/section09/${userAgent}/section9-1.png`}
        srcSet={`
                /assets/alerttalk/section09/${userAgent}/section9-1@2x.png 2x,
                /assets/alerttalk/section09/${userAgent}/section9-1@3x.png 3x,
            `}
        alt="section9-1"
      />
    </section>
  );
}

export default AlertTalkSection9