import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import { UserAgentType } from "../../../interface/common/common.inferface";
import "./css/index.css";

type Props = {
  userAgent: UserAgentType;
};
const AlertTalkSection5 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.2);
  const targetRef2 = useIntersectionObserver(0.2);

  const uploadArr = [
    {
      img: "excel",
      mainText: "엑셀 파일",
      subText:
        userAgent !== "mb"
          ? `상품 목록, 고객 정보를
엑셀 파일로 일괄 업로드 할 수 있어요`
          : `상품 목록, 고객 정보를
엑셀 파일로 일괄 업로드
할 수 있어요`,
    },
    {
      mainText: "포스 프로그램 연동",
      subText:
        userAgent !== "mb"
          ? `SKPOS 프로그램에서 상품과 회원
목록을 간편하게 전송할 수 있어요`
          : `SKPOS 프로그램에서 상품과
회원 목록을 간편하게
전송할 수 있어요`,
    },
  ];

  return (
    <section className="alert-talk-section5">
      <p className="alert-talk-section5-title hidden-section" ref={targetRef}>
        상품, 회원 업로드
      </p>
      <div className="upload-img-wrapper hidden-section" ref={targetRef2}>
        {uploadArr.map(({ img, mainText, subText }, idx) => (
          <div className="upload-box" key={idx}>
            <div className="upload-text-wrapper">
              <p className="upload-main-text">{mainText}</p>
              <p className="upload-sub-text">{subText}</p>
            </div>
            <div className="upload-img-box">
              {img ? (
                <img
                  className="upload-img"
                  src={`/assets/alerttalk/section05/${userAgent}/${img}.png`}
                  srcSet={`
                        /assets/alerttalk/section05/${userAgent}/${img}@2x.png 2x,
                        /assets/alerttalk/section05/${userAgent}/${img}@3x.png 3x,
                    `}
                  alt={img}
                />
              ) : (
                <div className="skpos-logo">
                  <img
                    src={`/assets/alerttalk/section05/${userAgent}/img-logo.png`}
                    srcSet={`
                    /assets/alerttalk/section05/${userAgent}/img-logo@2x.png 2x,
                    /assets/alerttalk/section05/${userAgent}/img-logo@3x.png 3x,
                  `}
                    alt="img-logo"
                  />
                  <img
                    src={`/assets/mainsection4/${userAgent}/text-logo.png`}
                    srcSet={`
                    /assets/mainsection4/${userAgent}/text-logo@2x.png 2x,
                    /assets/mainsection4/${userAgent}/text-logo@3x.png 3x,
                  `}
                    className="text-logo"
                    alt="text-logo"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AlertTalkSection5;

