import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import { UserAgentType } from "../../../interface/common/common.inferface";
import "./css/index.css";
type Props = {
  userAgent: UserAgentType;
};
const AlertTalksection6 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.2);
  const targetRef2 = useIntersectionObserver(0.3);
  const targetRef3 = useIntersectionObserver(0.4);
  const targetRef4 = useIntersectionObserver(0.5);

  return (
    <section className="alert-talk-section6">
      <img
        src={`/assets/alerttalk/section06/${userAgent}/gradient.png`}
        srcSet={`
                    /assets/alerttalk/section06/${userAgent}/gradient@2x.png 2x,
                    /assets/alerttalk/section06/${userAgent}/gradient@3x.png 3x,
                `}
        alt="gradient"
        className="gradient"
      />
      <div className="alert-talk-section6-wrapper">
        <div
          className="alert-talk-section6-text-wrapper hidden-section"
          ref={targetRef}
        >
          <p className="text-main">
            {userAgent !== "mb"
              ? `누구나 마케팅 서비스를 도입할 수 있어요`
              : `누구나 마케팅 서비스를 
도입할 수 있어요`}
          </p>
          <p className="text-sub">
            {userAgent !== "mb"
              ? `마케팅이 필요한 매장이라면 누구나 도입할 수 있습니다`
              : `마케팅이 필요한 매장이라면
누구나 도입할 수 있습니다`}
          </p>
        </div>
        <div className="img-wrapper hidden-section" ref={targetRef2}>
          {["SKPOS 가맹점", "타사 포스 사용매장", "누구나"].map((item, idx) => (
            <div className="img-box">
              <img
                key={idx}
                src={`/assets/alerttalk/section06/${userAgent}/alert-talk-section6-${
                  idx + 1
                }.png`}
                srcSet={`
                        /assets/alerttalk/section06/${userAgent}/alert-talk-section6-${
                  idx + 1
                }@2x.png 2x,
                        /assets/alerttalk/section06/${userAgent}/alert-talk-section6-${
                  idx + 1
                }@3x.png 3x
                `}
                alt={`alert-talk-section6-${item}`}
                className="img"
              />
              <div className="img-btn" key={idx}>
                {item}
              </div>
            </div>
          ))}
          {userAgent === "pc" && (
            <img
              className="line"
              src={`/assets/alerttalk/section06/${userAgent}/line.png`}
              srcSet={`
                    /assets/alerttalk/section06/${userAgent}/line@2x.png 2x,
                    /assets/alerttalk/section06/${userAgent}/line@3x.png 3x
                    `}
              alt="line"
            />
          )}
        </div>
      </div>
      <div className="motion-wrapper">
        <div className="text-wrapper hidden-section" ref={targetRef3}>
          <p className="main-text">
            {userAgent !== "mb"
              ? `온라인 매출과 오프라인 매출을 통합 관리하세요`
              : `온라인 매출과 오프라인 
매출을 통합 관리하세요`}
          </p>
          <p className="sub-text">앱 주문은 포스와 실시간 연동돼요</p>
        </div>
        <div className="video-box hidden-section" ref={targetRef4}>
          <video
            loop
            autoPlay
            muted
            playsInline
            src="/assets/alerttalk/section06/video.mp4"
          />
        </div>
      </div>
    </section>
  );
};

export default AlertTalksection6;

const imgArr = [
  {
    text: "SKPOS 가맹점",
  },
  {
    text: "타사 포스 사용매장",
  },
  {
    text: "누구나",
  },
];
