import "./css/index.css";
import { UserAgentType } from '../../../interface/common/common.inferface'
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
type Props ={
    userAgent:UserAgentType;
}
const AlertTalkSection8 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.2);

  return (
    <section className="alert-talk-section8">
      <div
        className="alert-talk-section8-wrapper hidden-section"
        ref={targetRef}
      >
        {imgArr.map(({ text, subText }, idx) => (
          <div className="img-box">
            <img
              src={`/assets/alerttalk/section08/${userAgent}/img${idx + 1}.png`}
              srcSet={`
                    /assets/alerttalk/section08/${userAgent}/img${
                idx + 1
              }@2x.png 2x,
                    /assets/alerttalk/section08/${userAgent}/img${
                idx + 1
              }@3x.png 3x,
                    `}
              alt=""
            />
            <div className="text-wrapper">
              <p className="text">{text}</p>
              <p className="sub-text">{subText}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AlertTalkSection8;

const imgArr = [
  {
    text: "포스 프로그램",
    subText: "쉽고 빠른 매장관리",
  },
  {
    text: "마케팅",
    subText: "스마트한 고객 연결 마케팅",
  },
  {
    text: "모바일 앱",
    subText: `언제 어디서나 쉽게 사용하는
매장 관리`,
  },
];