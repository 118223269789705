import{ useEffect, useState } from 'react'
import MainSection9 from '../MainSection9'
import { UserAgentType } from '../../../../interface/common/common.inferface'
import useIntersectionObserver from '../../../../hooks/UseIntersectionObserver';
type Props={
    userAgent:UserAgentType;
}
const MainSection9Container = ({userAgent}:Props) => {
    const targetRef = useIntersectionObserver(0.3);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
      return () => clearInterval(interval);
    }, []);
  return (
    <MainSection9
      ref={targetRef}
      userAgent={userAgent}
      currentIndex={currentIndex}
      images={images}
    />
  );
}
const images = ["img1","img2","img3"]
export default MainSection9Container