import React from "react";
import "./css/index.scss";
import SliderContentContainer from "../../SliderContent/container/SliderContentContainer";
import { UserAgentType } from "../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";

type Props = {
  userAgent: UserAgentType;
  contentArr: {
    title: string;
    sub: string;
  }[];
  currentIndex: number;
};

const MainSection2 = ({ userAgent, contentArr, currentIndex }: Props) => {
  const targetRef = useIntersectionObserver(0.3);
  return (
    <section className="main-section2 ">
      <div className="content-wrapper hidden-section" ref={targetRef}>
        <div className="content-title-wrapper">
          <p className="content-title">도입 현장</p>
          <p className="content-sub-title">사장님들의 생생한 사용 후기</p>
        </div>
        <div className="satisfaction-wrapper ">
          {contentArr.map(({ title, sub }, idx) => (
            <div
              className={`satisfaction-card ${
                currentIndex === idx && "active"
              }`}
              key={idx}
            >
              <p className="satisfaction-title">{title}</p>
              <p className="satisfactio-sub">{sub}</p>
            </div>
          ))}
        </div>
      </div>
      <SliderContentContainer userAgent={userAgent} />
    </section>
  );
};

export default MainSection2;
