import { forwardRef } from "react";
import "./css/index.css";
import { ReactComponent as FreeSvg } from "./svg/free.svg";
import { ReactComponent as SubscriptionSvg } from "./svg/subscription.svg";
import { ReactComponent as MonthCostSvg } from "./svg/monthcost.svg";
import { ReactComponent as FunctionSvg } from "./svg/function.svg";
import { UserAgentType } from "../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import DynamicContentButton from "../../DynamicContentButton/DynamicContentButton";
import ContentImg from "../../ContentImg/ContentImg";
type Props = {
  crtIdx: number;
  userAgent: UserAgentType;
  SelectedBtnClicked: (idx: number) => void;
};

const MainSection5 = forwardRef<HTMLDivElement, Props>(
  ({ userAgent, crtIdx, SelectedBtnClicked }, ref) => {
    const ref2 = useIntersectionObserver(0.5);
    const menuArr = [
      {
        title: "포스앱, 모바일 앱",
        sub: `마케팅 플랫폼 무료로
사용하세요`,
        svg: <FreeSvg />,
      },
      {
        title: "가입비 0원",
        sub: `복잡한
계약서가 없어요 `,
        svg: <SubscriptionSvg />,
      },
      {
        title: "월비용 0원",
        sub: `월 사용료, 
구독료 내지마세요`,
        svg: <MonthCostSvg />,
      },
      {
        title: "기능제한 없음",
        sub: `회원관리, 마케팅 등
모든 기능을 사용할 수 있어요`,
        svg: <FunctionSvg />,
      },
    ];

    const imgArr = [
      {
        src: "license1",
        title:
          userAgent === "pc"
            ? "포스앱, 모바일 매출 조회 앱, 택 발행"
            : `포스앱, 모바일 매출
조회 앱, 택 발행`,
        sub: (
          <>
            <b>마케팅 플랫폼</b>을{userAgent !== "pc" && <br />} 무료로 사용할
            수 있어요
          </>
        ),
      },
      {
        src: "license2",
        title: "계약서가 없어요",
        sub: "계약서 없이 누구나 사용 가능해요",
      },
      {
        src: "license3",
        title: "월 비용을 내지 마세요",
        sub:
          userAgent === "pc"
            ? "포스기, 노트북, 태블릿에 다운받고 월 비용없이 사용하세요"
            : `포스기, 노트북, 태블릿에 다운받고
월 비용없이 사용하세요`,
      },
      {
        src: "license4",
        title:
          userAgent === "pc"
            ? "포스 앱 구독 비용을 내지 마세요"
            : `포스 앱 구독 비용을
내지마세요`,
        sub: `구독없이 모든 기능을 사용할 수 있어요`,
      },
    ];
    return (
      <section className={`open-license `}>
        <div className="open-license-wrapper">
          <div className="license-title hidden-section" ref={ref}>
            {userAgent !== "mb"
              ? `오픈 라이센스, 구독없이
모든 기능을 무료로 사용하세요`
              : `오픈 라이센스, 구독없이 
모든 기능을 무료로 
사용하세요`}
          </div>
          <div className="selected-wrapper hidden-section" ref={ref2}>
            <div className="btn-wrapper">
              {menuArr.map(({ title, sub, svg }, idx) => (
                <DynamicContentButton
                  crtIdx={crtIdx}
                  idx={idx}
                  Active={SelectedBtnClicked}
                  svg={svg}
                  title={title}
                  sub={sub}
                />
              ))}
            </div>
            <ContentImg
              title={imgArr[crtIdx].title}
              sub={imgArr[crtIdx].sub}
              src={imgArr[crtIdx].src}
            />
          </div>
        </div>
      </section>
    );
  }
);

export default MainSection5;
