import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import MainSection11Card from "../../MainSection11Card/MainSection11Card";
type Props = {
  userAgent: UserAgentType;
};
const MainSection11 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.1);
  return (
    <section className="main-section11 ">
      <div className="wrapper hidden-section" ref={targetRef}>
        <div className="text-wrapper ">
          <p className="text">마케팅 하면 매출이 올라요</p>
          <p className="sub-text">쉽게하는 요즘 마케팅</p>
        </div>
        <div className="card-wrapper ">
          {cardArr.map(({ title, sub, img }, idx) => (
            <MainSection11Card
              key={idx}
              userAgent={userAgent}
              title={title}
              sub={sub}
              src={img}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default MainSection11;

const cardArr = [
  {
    title: "모바일 시대",
    sub: `모바일로 홍보하세요 
홍보 효과가 가장 좋아요`,
    img: "img1",
  },
  {
    title: "알림톡",
    sub: `고객에게 가장 익숙한 매체로 적은 
비용으로 큰 효과를 볼 수 있어요`,
    img: "img2",
  },
  {
    title: "동네상권 마케팅",
    sub: `구매 확률이 높은 우리동네
고객에게 직접 마케팅 하세요`,
    img: "img3",
  },
  {
    title: "꾸준한 마케팅",
    sub: `꾸준한 마케팅은 매출 상승으로
이어져요. 지금 시작하세요`,
    img: "img4",
  },
];
