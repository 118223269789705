import React from "react";
import "./css/index.css";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
import { UserAgentType } from "../../interface/common/common.inferface";

type Props = {
  userAgent: UserAgentType;
  idx: number;
  text: string;
  imgSrc:string;
};
const MbAlertTalk = ({ userAgent, idx, text, imgSrc }: Props) => {
  const targetRef = useIntersectionObserver(0.1);
  return (
    <div className="mb-alert-talk hidden-section" ref={targetRef}>
      <div className="menu">{text}</div>
      <img
        key={idx}
        src={`/assets/appPromotion/${userAgent}/${imgSrc}${idx + 1}.png`}
        srcSet={`/assets/appPromotion/${userAgent}/${imgSrc}${
          idx + 1
        }@2x.png 2x,
                    /assets/appPromotion/${userAgent}/${imgSrc}${
          idx + 1
        }@3x.png 3x,    
                    `}
        alt=""
      />
    </div>
  );
};

export default MbAlertTalk;
