import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../../interface/common/common.inferface';
import useIntersectionObserver from '../../../hooks/UseIntersectionObserver';
type Props={
    userAgent:UserAgentType;
}
const AlertTalkSection4 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.2);
  const targetRef2 = useIntersectionObserver(0.3);

  return (
    <section className="alert-talk-section4">
      <div className="alert-talk-section4-text-wrapper hidden-section" ref={targetRef}>
        <p className="main-text">요즘 마케팅</p>
        <p className="sub-text">마케팅이 이렇게 쉬워요</p>
      </div>
      <div className="img-wrapper hidden-section" ref={targetRef2} >
        {imgArr.map(({ img, mainText, subText }, idx) => (
          <div className="img-box" key={idx}>
            <img
              src={`/assets/alerttalk/section04/${userAgent}/${img}.png`}
              srcSet={`
                        /assets/alerttalk/section04/${userAgent}/${img}@2x.png 2x,
                        /assets/alerttalk/section04/${userAgent}/${img}@3x.png 3x,
                    `}
              alt=""
            />
            <div className="img-text-wrapper">
              <p className="img-main-text">{mainText}</p>
              <p className="img-sub-text">{subText}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AlertTalkSection4;

const imgArr = [
  {
    img: "alertsection4-1",
    mainText: "대쉬보드",
    subText: `전단지 제작, 알림톡 발송,
통계를 확인할 수 있어요`,
  },
  {
    img: "alertsection4-2",
    mainText: "무료 이미지",
    subText: `전단지 제작에 필요한 5만여개
이미지를 무료로 제공해요`,
  },
  {
    img: "alertsection4-3",
    mainText: "샘플 전단지",
    subText: `소요 시간 10분! 
클릭 만으로 누구나 쉽고 
간단하게 제작할 수 있어요`,
  },
];