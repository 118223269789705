import "./css/index.css";
import { UserAgentType } from '../../../interface/common/common.inferface';
import useIntersectionObserver from '../../../hooks/UseIntersectionObserver';
import Payment from '../../Payment/Payment';
type Props={
    userAgent:UserAgentType;
}
const MainSection7 = ({userAgent}:Props) => {
    const ref1 = useIntersectionObserver(0.3);
    const ref2 = useIntersectionObserver(0.4);

  return (
    <section className="main-section7 ">
      <div className="wrapper">
        <div className="text-wrapper hidden-section" ref={ref1}>
          <p className="text">다양한 결제 수단</p>
          <p className="sub-text">{`애플페이, 네이버 페이 등
모든 결제 수단 지원`}</p>
        </div>
        <div className="pay-wrapper hidden-section" ref={ref2}>
          {payArr.map(({ src, text }, idx) => (
            <Payment src={src} text={text}  userAgent={userAgent} key={idx} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default MainSection7;

const payArr=[
    {
        src:"naver",
        text:"네이버 페이"
    },
    {
        src:"kakao",
        text:"카카오 페이"
    },
    {
        src:"samsung",
        text:"삼성 페이"
    },
    {
        src:"zero",
        text:"제로 페이"
    },
    {
        src:"apple",
        text:"애플 페이"
    },
    {
        src:"card",
        text:"카드 결제"
    },
    {
        src:"qr-code",
        text:"QR코드"
    },
    {
        src:"barcode",
        text:"바코드"
    },
    {
        src:"cash",
        text:"현금 결제"
    }
]