import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import MainSection8Card from "../../MainSection8Card/MainSection8Card";
type Props = {
  userAgent: UserAgentType;
};
const MainSection8 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.3);
  const contentArr = [
    {
      src: "img1",
      title: "저울 바코드 스캔만 하면 끝-",
      sub:
        userAgent === "pc"
          ? `상품별로 이익율을 설정해 보세요 
원가와 이익금을 알아서 계산해 드립니다`
          : `상품별로 이익율을 설정해 보세요
원가와 이익금을 알아서 
계산해 드립니다`,
    },
    {
      src: "img2",
      title: "바코드 출력",
      sub: `상품별 바코드를 자동 생성하고
언제든 인쇄할 수 있습니다`,
    },
  ];

  return (
    <section className="main-section8 ">
      <div className="wrapper">
        <div className="title-wrapper hidden-section" ref={targetRef}>
          {userAgent !== "mb"
            ? `정육점, 청과, 수산물 매장
저울상품 취급하신다면 SKPOS가 정답`
            : `정육점, 청과, 수산물 매장
저울상품 취급하신다면
SKPOS가 정답`}
        </div>
        <div className="content-wrapper">
          {contentArr.map(({ src, title, sub }, idx) => (
            <MainSection8Card
              key={idx}
              src={src}
              title={title}
              sub={sub}
              userAgent={userAgent}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default MainSection8;
