import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import TrendTopBox from "../../TrendTopBox/TrendTopBox";
import TrendMiddleBox from "../../TrendMiddleBox/TrendMiddleBox";
import TrendBottomBox from "../../TrendBottomBox/TrendBottomBox";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import PcTrendBox from "../../PcTrendBox/PCTrendBox";
type Props = {
  userAgent: UserAgentType;
};
const MainSection4 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.3);
  const targetRef2 = useIntersectionObserver(0.4);
  return (
    <section className="main-section4 ">
      <div className="title hidden-section" ref={targetRef}>{`SKPOS가 만들어가는
포스 솔루션의 새로운 트렌드`}</div>
      <div className="trend-info-wrapper hidden-section" ref={targetRef2}>
        {userAgent !== "mb" ? (
          <PcTrendBox userAgent={userAgent} />
        ) : (
          <>
            <TrendTopBox userAgent={userAgent} />
            <TrendMiddleBox userAgent={userAgent} />
            <TrendBottomBox userAgent={userAgent} />
          </>
        )}
      </div>
    </section>
  );
};

export default MainSection4;
