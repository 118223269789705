import "./css/index.css";

type Props = {
  crtIdx: number;
  TextToggleOpen:(idx:number)=>void;
};
const KaKaoLeaflet = ({ crtIdx, TextToggleOpen }: Props) => {
  return (
    <div className="kakao-leaflet-wrapper">
      <p className="leaflet-text">카카오 알림톡 전단지</p>
      <div className="leaflet-btn-wrapper">
        {["정육점", "수산청과", "도소매"].map((item, idx) => (
          <button
            className="leaflet-btn"
            style={{background: idx +3 === crtIdx ? "var(--main)" : "" }}
            key={idx}
            onClick={() => TextToggleOpen(idx)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default KaKaoLeaflet;