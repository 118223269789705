import "./css/index.css";
import useIntersectionObserver from '../../hooks/UseIntersectionObserver';
import { UserAgentType } from '../../interface/common/common.inferface';
type Props={
    userAgent:UserAgentType;
    imgSrc:string;
}
const PcAlertTalk = ({ userAgent, imgSrc }: Props) => {
  const targetRef = useIntersectionObserver(0.1);
  const targetRef2 = useIntersectionObserver(0.1);

  return (
    <div className="pc-alert-talk">
      <div className="menu-wrapper hidden-section" ref={targetRef}>
        <div className="line" />
        {["알림톡 발송", "행사 전단", "구매 | 결제"].map((item, idx) => (
          <div className="menu-box" key={idx}>
            <div className="menu">{item}</div>
          </div>
        ))}
      </div>
      <div className="img-wrapper hidden-section" ref={targetRef2}>
        {[1, 2, 3].map((number, idx) => (
          <img
            key={idx}
            src={`/assets/appPromotion/${userAgent}/${imgSrc}${number}.png`}
            srcSet={`
                    /assets/appPromotion/${userAgent}/${imgSrc}${number}@2x.png 2x,    
                    /assets/appPromotion/${userAgent}/${imgSrc}${number}@3x.png 3x,    
                    `}
            alt=""
          />
        ))}
      </div>
    </div>
  );
};

export default PcAlertTalk