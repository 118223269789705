import "./css/index.css"
import useIntersectionObserver from '../../hooks/UseIntersectionObserver';
import { UserAgentType } from '../../interface/common/common.inferface';
import PcAlertTalk from '../AlertTalk/PcAlertTalk';
import MbAlertTalk from '../AlertTalk/MbAlertTalk';
type Props = {
  userAgent: UserAgentType;
  imgSrc:string;
};
const AppPromotion = ({ userAgent, imgSrc }: Props) => {
  const targetRef = useIntersectionObserver(0.2);
  return (
    <div className="app-promotion">
      <div className="wrapper">
        <div className="top-box">
          <div className="text-wrapper hidden-section" ref={targetRef}>
            <p className="text">앱 설치 하지 마세요</p>
            <p className="sub-text">
              {userAgent !== "mb"
                ? `제품 홍보 부터 구매, 결제까지 이 모든걸 알림톡만 발송하면 끝`
                : `제품 홍보 부터 구매, 결제까지
이 모든걸 알림톡만 발송하면 끝`}
            </p>
          </div>
          {userAgent !== "mb" ? (
            <PcAlertTalk imgSrc={imgSrc} userAgent={userAgent} />
          ) : (
            <div className="mobile-box">
              {["알림톡 발송", "행사 전단", "구매 | 결제"].map((text, idx) => (
                <MbAlertTalk
                  key={idx}
                  imgSrc={imgSrc}
                  text={text}
                  idx={idx}
                  userAgent={userAgent}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppPromotion;