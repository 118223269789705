import "./css/index.css";
type Props={
    idx:number;
    crtIdx:number;
    mainText:string;
    subText:string;
    TextToggleOpen:(idx:number)=>void;
}
const MarketingToggle = ({
  idx,
  crtIdx,
  mainText,
  subText,
  TextToggleOpen,
}: Props) => {
  return (
    <div 
        className={`marketing-toggle-btn ${crtIdx === idx && "marketing-toggle-open"}`} 
        onClick={() => TextToggleOpen(idx )}
    >
      <button className="main-toggle-btn">
        <p className={`main-text ${crtIdx === idx && "color-main-text"}`}>
          {mainText}
        </p>
        <img 
            src="/assets/alerttalk/section03/arrow.svg" alt="arrow"
        />
      </button>
      {idx === crtIdx && <div className="sub-text">{subText}</div>}
    </div>
  );
};

export default MarketingToggle