import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import MarketingToggle from "../../MarketingToggle/MarketingToggle";
import KaKaoLeaflet from "../../KaKaoLeaflet/KaKaoLeaflet";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";

type Props = {
  userAgent: UserAgentType;
  crtIdx: number;
  imgIdx: number;
  LeafletImgClicked: (btnIdx: number) => void;
  TextToggleOpen: (idx: number) => void;
};

const AlertTalkSection3 = ({
  userAgent,
  crtIdx,
  imgIdx,
  LeafletImgClicked,
  TextToggleOpen,
}: Props) => {
     const targetRef = useIntersectionObserver(0.2);
     const targetRef2 = useIntersectionObserver(0.3);
  return (
    <section className="alert-talk-section3">
      <div className="alert-talk-section3-wrapper">
        <div className="alert-talk-section3-text-wrapper hidden-section"
          ref={targetRef}
        >
          <p className="main-text">
            {userAgent !== "mb"
              ? `나만 몰랐던! 매출 올리는 마케팅 비법`
              : `나만 몰랐던!
매출 올리는 마케팅 비법`}
          </p>
          <p className="sub-text">
            {userAgent !== "mb"
              ? `매출 상승 효과를 직접 경험해 보세요`
              : `제품 홍보 부터 구매, 결제까지
이 모든걸 알림톡만 발송하면 끝`}
          </p>
        </div>
        <div className="marketing-img-wrapper hidden-section" ref={targetRef2}>
          <div className="left-wrapper">
            {marketingArr.map(({ mainText, subText }, idx) => (
              <MarketingToggle
                idx={idx}
                crtIdx={crtIdx}
                mainText={mainText}
                subText={subText}
                TextToggleOpen={TextToggleOpen}
              />
            ))}
            {crtIdx === 2 && (
              <KaKaoLeaflet
                TextToggleOpen={LeafletImgClicked}
                crtIdx={imgIdx}
              />
            )}
          </div>
          <div className="right-wrapper">
            <img
              src={`/assets/alerttalk/section03/${userAgent}/marketing${imgIdx}.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AlertTalkSection3;

const marketingArr = [
  {
    mainText: "동네상권 마케팅",
    subText: `구매 가능성이 높은 우리동네
고객에게 타겟 마케팅 하세요`,
    img: "marketing1",
  },
  {
    mainText: "전화번호만 있으면 끝",
    subText: `아무런 준비도 필요 없습니다. 
고객 휴대폰으로 알림톡만 발송하세요. 
제품 홍보부터 구매, 결제까지
한 번에 할 수 있어요`,
    img: "marketing2",
  },
  {
    mainText: "매출이 쑥!쑥! 올라요",
    subText: `5,000만 인구 중 94%가 사용 중인
카카오 알림톡으로 마케팅 하세요`,
    img: "marketing3",
  },
];
