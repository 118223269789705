import { useCallback, useState } from "react";
import { UserAgentType } from "../../../../interface/common/common.inferface";
import AlertTalkSection3 from "../AlertTalkSection3";

type Props = {
  userAgent: UserAgentType;
};
const AlertTalkSection3Container = ({ userAgent }: Props) => {
  const [crtIdx, setCrtIdx] = useState<number>(0);
  const [imgIdx, setImgIdx] = useState<number>(1);

  const TextToggleOpen = useCallback((idx: number) => {
    setCrtIdx(idx);
    if (idx !== 3) {
      setImgIdx(idx + 1);
    }
  }, []);

  const LeafletImgClicked = useCallback(
    (btnIdx: number) => {
      if (crtIdx === 2) {
        setImgIdx(3 + btnIdx);
      }
    },
    [crtIdx]
  );
  return (
    <AlertTalkSection3
      userAgent={userAgent}
      crtIdx={crtIdx}
      imgIdx={imgIdx}
      LeafletImgClicked={LeafletImgClicked}
      TextToggleOpen={TextToggleOpen}
    />
  );
};

export default AlertTalkSection3Container;
