import  { useCallback } from "react";
import HoverInquiry from "../HoverInquiry";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const HoverInquiryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const MenuClicked = useCallback(
    (link: string) => {
      return navigate(link);
    },
    [navigate, dispatch]
  );

  return <HoverInquiry MenuClicked={MenuClicked} />;
};

export default HoverInquiryContainer;
