import React, { useCallback, useEffect, useState } from "react";
import "./css/index.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
interface PopupDataInterface {
  title: string;
  content: string;
  link: string;
  time: string;
  [key: string]: string;
}

const FirstPopup = () => {
  const [PopupData, setPopupData] = useState<PopupDataInterface | undefined>(
    undefined
  );

  const CloseOneDay = ()=>{
            const now = new Date();
        now.setDate(now.getDate() + 1);
        document.cookie = `ischeck=true;expires=${now.toUTCString()}`;
        setPopupData(undefined)
  }

  const SubmitBtn = useCallback(
    () => {
      setPopupData(undefined)
    },
    [],
  )
  

  useEffect(() => {
         const cookie =  document.cookie;
     const cookie_array = cookie.split("ischeck=");
    if (cookie_array[1]) {
      return 
    }

    getDoc(doc(db, "config", "popup")).then((res) => {
      if (res.exists()) {
        const resData = res.data() as PopupDataInterface
        const timeChange = resData.time.substring(0,4) + "-" + resData.time.substring(4,6) + "-" + resData.time.substring(6,8)
        const popupTime = new Date(timeChange)
     popupTime.setHours(0)
     if (Date.now() <= popupTime.getTime()) {
              setPopupData(resData);
     }
      }
    });

    return () => {};
  }, []);

  if (PopupData) {
    return <div className="first-popup">
      <div className="first-popup-box">
                  <button
            className="cancel-btn"
            onClick={() => {
       setPopupData(undefined)
            }}
          >
            <img src="/assets/popup/cancel.svg" alt="닫기" />
          </button>
        <div className="title">{PopupData.title}</div>
                <div className="sub">{PopupData.content}</div>
<div className="down-btn-wrapper">
     {PopupData.link ?             <a href={PopupData.link} className="first-popup-link" >확인하기</a> :
                  <button  className="first-popup-link" onClick={SubmitBtn}>확인하기</button>
     }
                <button className="first-popup-link popup-btn" onClick={CloseOneDay}>오늘 하루 보지않기</button>
</div>
      </div>
    </div>;
  }
  return <></>;
};

export default FirstPopup;
